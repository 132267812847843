<template>
    <div class="px-lg-5">
        <div class="bs-4 py-5 px-md-7 px-5 card b-1">

            <div class="row">
                <div class="col-12">
                    <h4 class="text-center mb-3 font-inter-bold">Profile Update</h4>
                    <div class="col-12">
                        <div class="fl-x fl-j-c mb-4">
                            <div style="width: 5rem;height: 5rem; border-radius: 50%"
                                 class="bg-primary text-center fl-x-cc">
                        <span class="m-0">
                            <i class="fa fa-user fa-2x"></i>
                        </span>
                            </div>
                        </div>
                    </div>
                </div>

                <s-form class="row" @submit="updateProfile" ref="formValidator">

                    <div class="col-12 col-md-6">
                        <div class="px-lg-3">
                            <validated-input label="First Name*" class="c-input-1" placeholder=""
                                             v-model="model.first_name" :rules="{required : true}"
                                             :disabled="loading" name="First Name">
                                <template #append>
                                </template>
                            </validated-input>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="px-lg-3">

                            <validated-input class="c-input-1" label="Last Name*" placeholder=""
                                             v-model="model.last_name" :rules="{required : true}"
                                             :disabled="loading" name="Last Name"/>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="px-lg-3">

                            <validated-input class="c-input-1" :rules="{required:true}" label="Email ID*" type="email"
                                             placeholder=""
                                             v-model="model.email"/>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="px-lg-3">
                            <validated-input :rules="{required:true}" label="Mobile Number*" type="number" placeholder=""
                                             v-model="model.mobile_number" name="Mobile number"
                                             class="c-input-1 c-input-number-modify-arrow-none"/>
                        </div>
                    </div>

                    <div class="col-12 col-md-6" v-if="!currentUser.is_superuser">
                        <div class="px-lg-3">

                            <validated-input class="c-input-1" label="Designation*" placeholder=""
                                             name="Employee role" :rules="{required:true}"
                                             v-model=model.employee_role :disabled="loading"/>
                        </div>
                    </div>

                    <div class="col-12 col-md-6" v-if="!currentUser.is_superuser">
                        <div class="px-lg-3">
                            <validated-input class="c-input-1" label="Employee Number*" placeholder=""
                                             name="Employee number" :rules="{required:true}"
                                             v-model="model.employee_number" :disabled="loading"/>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="fl-x fl-j-c mt-5">

                            <div class="fl-x btn-group">
                                <btn text="Cancel" :disabled="loading" type="button"
                                     design="basic-b" class="mr-3 px-4" @click="$router.push({path: '/'})"></btn>

                                <btn text="Update" :disabled="loading" :loading="loading"
                                     loading-text="Updating..." design="basic-a" class="px-4"></btn>
                            </div>

                        </div>
                    </div>
                </s-form>
            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import urls from '@/data/urls';
import axios from 'secure-axios';
import store from '@/store';

export default {
    name : 'ProfileUpdate',

    computed : { ...mapGetters(['currentUser']) },

    data () {
        return {
            loading : false,

            model : {
                id              : '',
                first_name      : '',
                last_name       : '',
                email           : '',
                mobile_number   : '',
                employee_role   : '',
                employee_number : ''
            },

            userType : 'normal',

            emailUpdate      : null,
            mobileUpdate     : null,
            profilePicUpdate : null,

            bankOptionUrl : urls.auction.bank.bankOptions

        };
    },

    mounted () {
        this.loadUser();
        this.loadUserData();
    },

    methods : {
        ...mapActions(['setUser']),

        async loadUser () {
            const response = await axios.get(urls.auth.status);

            console.log('response : ', response.data);
            if (response.data.loggedIn === true) {
                store.commit('setUser', response.data.user);
            }
        },

        loadUserData () {
            this.model.id = this.currentUser.user_id;
            this.model.first_name = this.currentUser.first_name;
            this.model.last_name = this.currentUser.last_name;
            this.model.email = this.currentUser.email;
            this.model.mobile_number = this.currentUser.mobile_number;
            this.model.employee_role = this.currentUser.employee_role;
            this.model.employee_number = this.currentUser.employee_number;
            // this.model.bank = this.currentUser.bank ? this.currentUser.bank.id : '';
        },

        async updateProfile () {
            this.loading = true;
            this.setModelNullValue(this.model);
            const response = await axios.form(urls.auth.profileUpdate, this.model);
            const json = response.data;
            if (json.error === false) {
                this.loading = false;
                this.formSuccess();
            } else {
                this.$refs.formValidator.setErrors(json.errors);
                this.formError();
            }
            this.loading = false;
        },

        setModelNullValue (model) {
            const keys = Object.keys(model);

            for (const i in keys) {
                if (model[keys[i]] === null || model[keys[i]] === undefined) {
                    model[keys[i]] = '';
                }
            }
        },

        formError () {
            this.$notify('Could not update profile', 'Error', {
                type : 'danger'
            });
        },

        formSuccess () {
            this.$router.go();
            this.$notify('Updated Successfully', 'Success', {
                type : 'success'
            });
        }

    }
};
</script>

<style scoped>
.pos-a-b-r {
    position: absolute;
    bottom: 6px;
    right: 16%;
}

.w-5re {
    width: 5rem;
    height: 5rem;
}

.w-h-100 {
    width: 100% !important;
    height: 100% !important;
}
</style>
